import React, { useState, useEffect } from 'react';
import '../styles/CustomerManagement.css';
import axios from 'axios';

const CustomerManagement = () => {
    const [customers, setCustomers] = useState([]);
    const [newCustomer, setNewCustomer] = useState({
        name: '',
        contactNumber: '',
        email: '',
        address: '',
        dateOfJoining: '',
        inquiryDone: false,
        purchasedModel: '',
        purchasedColour: '',
        purchaseDate: '',
        salePrice: '',
        paidAmount: '',
        paymentMethod: '',
        salesPerson: '',
        remarks: '',
        membershipLevel: '',
        customerFile: null
    });
    const [editCustomerId, setEditCustomerId] = useState(null);
    const [excelFile, setExcelFile] = useState(null);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewCustomer({ ...newCustomer, [name]: type === 'checkbox' ? checked : value });
    };

    const handleFileChange = (e) => {
        setNewCustomer({ ...newCustomer, customerFile: e.target.files[0] });
    };

    const handleExcelChange = (e) => {
        setExcelFile(e.target.files[0]);
    };

    const addOrUpdateCustomer = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        Object.keys(newCustomer).forEach((key) => {
            formData.append(key, newCustomer[key]);
        });

        if (editCustomerId) {
            formData.append('edit_customer', '1');
            formData.append('Customer_ID', editCustomerId);
        } else {
            formData.append('add_customer', '1');
        }

        try {
            const response = await axios.post('https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_customer.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.status === "success") {
                alert(response.data.message);
                fetchCustomerRecords();
                setEditCustomerId(null);
                resetForm();
            } else {
                alert(response.data.message || "An unexpected error occurred.");
            }
        } catch (error) {
            console.error("Error adding or updating customer:", error);
            alert("Failed to connect to the server. Check if the backend is running.");
        }
    };

    const fetchCustomerRecords = async () => {
        try {
            const response = await axios.get('https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_customer.php?action=fetch_all');
            if (Array.isArray(response.data)) {
                setCustomers(response.data);
            } else {
                setCustomers([]);
                console.error("Unexpected response format:", response.data);
            }
        } catch (error) {
            console.error("Error fetching customer records:", error);
        }
    };

    const handleEdit = (customer) => {
        setNewCustomer({
            name: customer.Name,
            contactNumber: customer.Contact_Number,
            email: customer.Email,
            address: customer.Address,
            dateOfJoining: customer.Date_Of_Joining,
            inquiryDone: customer.Inquiry_Done === 1,
            purchasedModel: customer.Purchased_Model,
            purchasedColour: customer.Purchased_Colour,
            purchaseDate: customer.Purchase_Date,
            salePrice: customer.Sale_Price,
            paidAmount: customer.Paid_Amount,
            paymentMethod: customer.Payment_Method,
            salesPerson: customer.Sales_Person,
            remarks: customer.Remarks,
            membershipLevel: customer.Membership_Level,
            customerFile: null
        });
        setEditCustomerId(customer.Customer_ID);
    };

    const handleDelete = async (customerId) => {
        if (window.confirm("Are you sure you want to delete this customer?")) {
            try {
                const formData = new FormData();
                formData.append("delete_customer", "1");
                formData.append("Customer_ID", customerId);

                const response = await axios.post('https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_customer.php', formData);

                if (response.data.status === "success") {
                    alert(response.data.message);
                    fetchCustomerRecords();
                } else {
                    alert(response.data.message || "An unexpected error occurred.");
                }
            } catch (error) {
                console.error("Error deleting customer:", error);
                alert("Failed to connect to the server.");
            }
        }
    };

    const resetForm = () => {
        setNewCustomer({
            name: '',
            contactNumber: '',
            email: '',
            address: '',
            dateOfJoining: '',
            inquiryDone: false,
            purchasedModel: '',
            purchasedColour: '',
            purchaseDate: '',
            salePrice: '',
            paidAmount: '',
            paymentMethod: '',
            salesPerson: '',
            remarks: '',
            membershipLevel: '',
            customerFile: null
        });
    };

    const handleUploadExcel = async () => {
        if (!excelFile) {
            alert("Please select an Excel file.");
            return;
        }
        const formData = new FormData();
        formData.append('excelFile', excelFile);

        try {
            const response = await axios.post('https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_customer.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.status === "success") {
                alert(response.data.message);
                fetchCustomerRecords();
            } else {
                alert(response.data.message || "An error occurred during the file upload.");
            }
        } catch (error) {
            console.error("Error uploading Excel:", error);
            alert("Failed to upload the Excel file.");
        }
    };

    const downloadExcel = async () => {
        window.location.href = 'https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_customer.php?action=download_excel';
    };

    useEffect(() => {
        fetchCustomerRecords();
    }, []);

    return (
        <div className="container mx-auto p-6 bg-dark-gray text-white rounded-lg shadow-lg">
            <h2 className="text-3xl font-bold mb-6 text-blue-400">Manage Customers</h2>
            <form className="mb-6" onSubmit={addOrUpdateCustomer}>
                <div className="grid grid-cols-2 gap-4">
                    <input type="text" name="name" placeholder="Name" className="p-2 bg-dark-input rounded" value={newCustomer.name} onChange={handleInputChange} />
                    <input type="text" name="contactNumber" placeholder="Contact Number" className="p-2 bg-dark-input rounded" value={newCustomer.contactNumber} onChange={handleInputChange} />
                    <input type="text" name="email" placeholder="Email" className="p-2 bg-dark-input rounded" value={newCustomer.email} onChange={handleInputChange} />
                    <textarea name="address" placeholder="Address" className="p-2 bg-dark-input rounded" value={newCustomer.address} onChange={handleInputChange} />
                    <input type="date" name="dateOfJoining" className="p-2 bg-dark-input rounded" value={newCustomer.dateOfJoining} onChange={handleInputChange} />
                    <label className="flex items-center"><input type="checkbox" name="inquiryDone" checked={newCustomer.inquiryDone} onChange={handleInputChange} /> Inquiry Done</label>
                    <input type="text" name="purchasedModel" placeholder="Purchased Model" className="p-2 bg-dark-input rounded" value={newCustomer.purchasedModel} onChange={handleInputChange} />
                    <input type="text" name="purchasedColour" placeholder="Purchased Colour" className="p-2 bg-dark-input rounded" value={newCustomer.purchasedColour} onChange={handleInputChange} />
                    <input type="date" name="purchaseDate" className="p-2 bg-dark-input rounded" value={newCustomer.purchaseDate} onChange={handleInputChange} />
                    <input type="number" name="salePrice" placeholder="Sale Price" className="p-2 bg-dark-input rounded" value={newCustomer.salePrice} onChange={handleInputChange} />
                    <input type="number" name="paidAmount" placeholder="Paid Amount" className="p-2 bg-dark-input rounded" value={newCustomer.paidAmount} onChange={handleInputChange} />
                    <input type="text" name="paymentMethod" placeholder="Payment Method" className="p-2 bg-dark-input rounded" value={newCustomer.paymentMethod} onChange={handleInputChange} />
                    <input type="text" name="salesPerson" placeholder="Sales Person" className="p-2 bg-dark-input rounded" value={newCustomer.salesPerson} onChange={handleInputChange} />
                    <textarea name="remarks" placeholder="Remarks" className="p-2 bg-dark-input rounded" value={newCustomer.remarks} onChange={handleInputChange} />
                    <input type="text" name="membershipLevel" placeholder="Membership Level" className="p-2 bg-dark-input rounded" value={newCustomer.membershipLevel} onChange={handleInputChange} />
                    <input type="file" onChange={handleFileChange} />
                </div>
                <button type="submit" className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded mt-4">
                    {editCustomerId ? 'Update Customer' : 'Add Customer'}
                </button>
                <button type="button" className="bg-blue-500 text-white px-4 py-2 rounded mt-4" onClick={downloadExcel}>
                    Download Excel
                </button>
                <div className="mt-4">
                    <input type="file" onChange={handleExcelChange} />
                    <button type="button" className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded mt-2" onClick={handleUploadExcel}>
                        Upload Excel
                    </button>
                </div>
            </form>

            <h3 className="text-2xl font-semibold mb-4 text-green-400">Customer Records</h3>
            <table className="w-full bg-dark-gray rounded border-collapse">
                <thead>
                    <tr className="bg-dark-header">
                        <th className="p-2 border">Name</th>
                        <th className="p-2 border">Contact</th>
                        <th className="p-2 border">Email</th>
                        <th className="p-2 border">Address</th>
                        <th className="p-2 border">Model</th>
                        <th className="p-2 border">Colour</th>
                        <th className="p-2 border">Price</th>
                        <th className="p-2 border">Paid</th>
                        <th className="p-2 border">Remaining</th>
                        <th className="p-2 border">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {customers.length > 0 ? (
                        customers.map((customer, index) => (
                            <tr key={index} className="hover:bg-dark-hover">
                                <td className="p-2 border">{customer.Name}</td>
                                <td className="p-2 border">{customer.Contact_Number}</td>
                                <td className="p-2 border">{customer.Email}</td>
                                <td className="p-2 border">{customer.Address}</td>
                                <td className="p-2 border">{customer.Purchased_Model}</td>
                                <td className="p-2 border">{customer.Purchased_Colour}</td>
                                <td className="p-2 border">{customer.Sale_Price}</td>
                                <td className="p-2 border">{customer.Paid_Amount}</td>
                                <td className="p-2 border">{customer.Remaining_Amount}</td>
                                <td className="p-2 border">
                                    <button onClick={() => handleEdit(customer)} className="bg-blue-500 text-white p-1 rounded mr-2">Edit</button>
                                    <button onClick={() => handleDelete(customer.Customer_ID)} className="bg-red-500 text-white p-1 rounded">Delete</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="10" className="p-2 text-center text-gray-400">No customer records available.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default CustomerManagement;
