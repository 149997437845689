import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EMICalculator = () => {
    const [bikeModels, setBikeModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState('');
    const [invoiceValue, setInvoiceValue] = useState('');
    const [marginMoney, setMarginMoney] = useState('');
    const [interestRate, setInterestRate] = useState('');
    const [tenure, setTenure] = useState(12);
    const [advanceMonths, setAdvanceMonths] = useState(0);
    const [dealerSubvention, setDealerSubvention] = useState(0);
    const [manufacturerSubvention, setManufacturerSubvention] = useState(0);
    const [manufacturerSubventionNotDeducted, setManufacturerSubventionNotDeducted] = useState(0);
    const [procFees, setProcFees] = useState(0);
    const [stampDuty, setStampDuty] = useState(0);
    const [dealerIncentive, setDealerIncentive] = useState(0);
    const [calculationResult, setCalculationResult] = useState(null);
    const [error, setError] = useState(null);

    // Fetch bike models from the backend (optional)
    useEffect(() => {
        const fetchBikeModels = async () => {
            try {
                const response = await axios.get('https://kunalbajajshowroom.coffeewithbugs.com/backend/get-bike-models.php');
                setBikeModels(response.data);
            } catch (error) {
                console.error("Failed to fetch bike models:", error);
            }
        };
        fetchBikeModels();
    }, []);

    const handleCalculate = async () => {
        setError(null);
        setCalculationResult(null);

        if (!invoiceValue || !marginMoney || !interestRate || !tenure) {
            setError("Please fill in all required fields.");
            return;
        }

        try {
            const response = await axios.post('https://kunalbajajshowroom.coffeewithbugs.com/backend/loan_calculations.php', {
                invoiceValue: parseFloat(invoiceValue),
                marginMoney: parseFloat(marginMoney),
                interestRate: parseFloat(interestRate),
                tenure: parseInt(tenure),
                advanceMonths: parseInt(advanceMonths),
                dealerSubvention: parseFloat(dealerSubvention),
                manufacturerSubvention: parseFloat(manufacturerSubvention),
                manufacturerSubventionNotDeducted: parseFloat(manufacturerSubventionNotDeducted),
                procFees: parseFloat(procFees),
                stampDuty: parseFloat(stampDuty),
                dealerIncentive: parseFloat(dealerIncentive)
            });

            if (response.data.error) {
                setError(response.data.error);
            } else {
                setCalculationResult(response.data);
            }
        } catch (error) {
            console.error("Calculation failed:", error);
            setError("Failed to perform the calculation. Please try again.");
        }
    };

    return (
        <div className="bg-gray-800 text-white p-6 rounded-md shadow-md max-w-2xl mx-auto mt-12">
            <h2 className="text-2xl font-bold mb-4">Bike EMI Calculator</h2>

            <div className="mb-4">
                <label className="block mb-2">Select Bike Model (Optional):</label>
                <select
                    value={selectedModel}
                    onChange={(e) => {
                        const selectedModelId = e.target.value;
                        setSelectedModel(selectedModelId);
                        if (selectedModelId !== '') {
                            const selectedBike = bikeModels.find(model => model.id.toString() === selectedModelId);
                            if (selectedBike) {
                                setInvoiceValue(selectedBike.price);
                            } else {
                                setInvoiceValue('');
                            }
                        } else {
                            setInvoiceValue('');
                        }
                    }}
                    className="w-full p-2 bg-gray-900 text-white rounded-md"
                >
                    <option value="">Select a Model</option>
                    {bikeModels.map((model) => (
                        <option key={model.id} value={model.id}>{model.model_name} - ₹{model.price}</option>
                    ))}
                </select>
            </div>

            <div className="mb-4">
                <label className="block mb-2">Invoice Value:</label>
                <input
                    type="number"
                    value={invoiceValue}
                    onChange={(e) => setInvoiceValue(e.target.value)}
                    className="w-full p-2 bg-gray-900 text-white rounded-md"
                />
            </div>

            <div className="mb-4">
                <label className="block mb-2">Margin Money:</label>
                <input
                    type="number"
                    value={marginMoney}
                    onChange={(e) => setMarginMoney(e.target.value)}
                    className="w-full p-2 bg-gray-900 text-white rounded-md"
                />
            </div>

            <div className="mb-4">
                <label className="block mb-2">Interest Rate (%):</label>
                <input
                    type="number"
                    value={interestRate}
                    onChange={(e) => setInterestRate(e.target.value)}
                    className="w-full p-2 bg-gray-900 text-white rounded-md"
                />
            </div>

            <div className="mb-4">
                <label className="block mb-2">Loan Tenure (Months):</label>
                <select
                    value={tenure}
                    onChange={(e) => setTenure(e.target.value)}
                    className="w-full p-2 bg-gray-900 text-white rounded-md"
                >
                    <option value={12}>12 Months</option>
                    <option value={18}>18 Months</option>
                    <option value={24}>24 Months</option>
                    <option value={36}>36 Months</option>
                    <option value={48}>48 Months</option>
                </select>
            </div>

            <div className="mb-4">
                <label className="block mb-2">Advance Months:</label>
                <input
                    type="number"
                    value={advanceMonths}
                    onChange={(e) => setAdvanceMonths(e.target.value)}
                    className="w-full p-2 bg-gray-900 text-white rounded-md"
                />
            </div>

            <div className="mb-4">
                <label className="block mb-2">Dealer Subvention:</label>
                <input
                    type="number"
                    value={dealerSubvention}
                    onChange={(e) => setDealerSubvention(e.target.value)}
                    className="w-full p-2 bg-gray-900 text-white rounded-md"
                />
            </div>

            <div className="mb-4">
                <label className="block mb-2">Manufacturer Subvention:</label>
                <input
                    type="number"
                    value={manufacturerSubvention}
                    onChange={(e) => setManufacturerSubvention(e.target.value)}
                    className="w-full p-2 bg-gray-900 text-white rounded-md"
                />
            </div>

            <div className="mb-4">
                <label className="block mb-2">Manufacturer Subvention Not Deducted:</label>
                <input
                    type="number"
                    value={manufacturerSubventionNotDeducted}
                    onChange={(e) => setManufacturerSubventionNotDeducted(e.target.value)}
                    className="w-full p-2 bg-gray-900 text-white rounded-md"
                />
            </div>

            <div className="mb-4">
                <label className="block mb-2">Processing Fees:</label>
                <input
                    type="number"
                    value={procFees}
                    onChange={(e) => setProcFees(e.target.value)}
                    className="w-full p-2 bg-gray-900 text-white rounded-md"
                />
            </div>

            <div className="mb-4">
                <label className="block mb-2">Stamp Duty:</label>
                <input
                    type="number"
                    value={stampDuty}
                    onChange={(e) => setStampDuty(e.target.value)}
                    className="w-full p-2 bg-gray-900 text-white rounded-md"
                />
            </div>

            <div className="mb-4">
                <label className="block mb-2">Dealer Incentive:</label>
                <input
                    type="number"
                    value={dealerIncentive}
                    onChange={(e) => setDealerIncentive(e.target.value)}
                    className="w-full p-2 bg-gray-900 text-white rounded-md"
                />
            </div>

            <button onClick={handleCalculate} className="bg-blue-600 px-4 py-2 rounded-md hover:bg-blue-700 transition">
                Calculate EMI and Metrics
            </button>

            {error && (
                <div className="mt-4 bg-red-600 p-2 rounded-md">
                    <p>{error}</p>
                </div>
            )}

            {calculationResult && (
                <div className="mt-6 bg-gray-700 p-4 rounded-md">
                    <h3 className="text-xl font-bold mb-2">Calculation Results</h3>
                    <p>Monthly EMI: ₹{parseFloat(calculationResult.monthly_emi).toFixed(2)}</p>
                    <p>Total Interest: ₹{parseFloat(calculationResult.total_interest).toFixed(2)}</p>
                    <p>Total Loan Payment: ₹{parseFloat(calculationResult.total_loan_payment).toFixed(2)}</p>
                    <p>Initial Payment: ₹{parseFloat(calculationResult.initial_payment).toFixed(2)}</p>
                    <p>Income: ₹{parseFloat(calculationResult.income).toFixed(2)}</p>
                    <p>Upfront Payment by Borrower: {parseFloat(calculationResult.upfront_payment_by_borrower).toFixed(2)}%</p>
                    <p>Gross LTV: {parseFloat(calculationResult.gross_ltv).toFixed(2)}%</p>
                    <p>Net LTV: {parseFloat(calculationResult.net_ltv).toFixed(2)}%</p>
                    <p>Disbursement Amount: ₹{parseFloat(calculationResult.disbursement_amt).toFixed(2)}</p>
                    <p>Initial Outflow: ₹{parseFloat(calculationResult.initial_outflow).toFixed(2)}</p>
                    <p>DP: ₹{parseFloat(calculationResult.dp).toFixed(2)}</p>
                    <p>DIS: ₹{parseFloat(calculationResult.dis).toFixed(2)}</p>
                    <p>Calculated IRR: {calculationResult.calculated_irr !== "N/A" ? `${parseFloat(calculationResult.calculated_irr).toFixed(2)}%` : "N/A"}</p>
                </div>
            )}
        </div>
    );
};

export default EMICalculator;
