import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'; // Updated imports for React Router v6
import FileSharing from './components/FileSharing';
import SocialMediaButtons from './components/SocialMediaButtons';
import ManagementDashboard from './components/ManagementDashboard';
import BikeManagement from './components/BikeManagement';
import TestBikeManagement from './components/TestBikeManagement';
import CustomerManagement from './components/CustomerManagement';
import AccessoryManagement from './components/AccessoryManagement';
import FinanceManagement from './components/FinanceManagement';
import Login from './components/Login';
import RegisterAdmin from './components/RegisterAdmin';
import ProtectedRoute from './components/ProtectedRoute';
import EMICalculator from './components/EMICalculator';


const App = () => {
    return (
        <Router>
            <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center">
                {/* Hero Section */}
                <header
                    className="relative w-full h-screen bg-cover bg-center flex items-center justify-center flex-col"
                    style={{ backgroundImage: `url('/images/showroom-background.jpeg')` }}
                >
                    <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-80"></div>
                    <div className="z-20 text-center px-4 md:px-8 animate-fadeInUp">
                        <h1 className="text-4xl md:text-6xl font-extrabold mb-4 text-white bg-blue-900 px-4 py-2 rounded-md shadow-lg">
                            🚗 Kunal Bajaj Showroom 🚗
                        </h1>
                        <p className="text-lg md:text-2xl font-light text-white bg-blue-900 px-3 py-1 rounded-md shadow-md">
                            Your trusted partner for Bajaj vehicles ⚙️
                        </p>
                    </div>
                </header>

                {/* Navigation Bar */}
                <nav className="bg-gray-800 w-full py-4 flex justify-center sticky top-0 z-50">
                    <Link to="/" className="px-6 text-white hover:text-yellow-500 font-bold">
                        Home
                    </Link>
                    <Link to="/management" className="px-6 text-white hover:text-yellow-500 font-bold">
                        Management
                    </Link>
                    <Link to="/emi" className="px-6 text-white hover:text-yellow-500 font-bold">
        EMI Calculator
    </Link>
                </nav>

                {/* Content Routes */}
                <Routes>
                    <Route
                        path="/"
                        element={
                            <main className="mt-10 md:mt-20 flex flex-col items-center space-y-12">
                                {/* Welcome Section */}
                                <section className="max-w-4xl text-center px-4 md:px-8">
                                    <p className="text-gray-300 text-lg md:text-2xl mb-8 leading-relaxed animate-fadeIn">
                                        Welcome to Kunal Bajaj Showroom! Explore the latest Bajaj bikes 🏍️, connect with us on WhatsApp, Instagram, or Facebook, or share important files 📂 directly with our team.
                                    </p>
                                </section>

                                {/* Social Media Buttons */}
                                <section className="flex flex-col items-center">
                                    <h2 className="text-3xl md:text-4xl font-bold mb-6 text-gray-200 animate-fadeIn">
                                        Connect with Us 🌐
                                    </h2>
                                    <SocialMediaButtons />
                                </section>

                                {/* File Sharing Section */}
                                <section className="file-sharing bg-gray-800 p-6 md:p-8 rounded-xl shadow-2xl transform transition duration-500 hover:scale-105 w-full max-w-lg md:max-w-xl">
                                    <h3 className="text-xl md:text-2xl font-semibold mb-4 text-gray-50">
                                        File Sharing 💾
                                    </h3>
                                    <FileSharing />
                                </section>
                            </main>
                        }
                    />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<RegisterAdmin />} />
                    <Route path="/management" element={<ProtectedRoute><ManagementDashboard /></ProtectedRoute>} />
                    <Route path="/management/bikes" element={<ProtectedRoute><BikeManagement /></ProtectedRoute>} />
                    <Route path="/management/test_bikes" element={<ProtectedRoute><TestBikeManagement /></ProtectedRoute>} />
                    <Route path="/management/customers" element={<ProtectedRoute><CustomerManagement /></ProtectedRoute>} />
                    <Route path="/management/accessories" element={<ProtectedRoute><AccessoryManagement /></ProtectedRoute>} />
                    <Route path="/management/finances" element={<ProtectedRoute><FinanceManagement /></ProtectedRoute>} />
                    <Route path="/emi" element={<EMICalculator />} />
                </Routes>

                {/* Footer */}
                <footer className="mt-12 md:mt-16 w-full py-6 bg-gray-800 text-center text-gray-400 animate-fadeInUp">
                    <p>&copy; 2024 Kunal Bajaj Showroom. All rights reserved. 💼</p>
                </footer>
            </div>
        </Router>
    );
};

export default App;
