import React from 'react';
import { FaWhatsapp, FaFacebook, FaInstagram, FaEnvelope } from 'react-icons/fa'; // Icons

const SocialMediaButtons = () => {
  const whatsappLink = 'https://api.whatsapp.com/send?phone=917987647711&text=Hello,%20I%20would%20like%20to%20contact%20the%20Bajaj%20Showroom';
  const facebookLink = 'https://www.facebook.com/sharer/sharer.php?u=https://bajaj-showroom.com';
  const instagramLink = 'https://www.instagram.com/kunalbajajshowroom/';
  const emailLink = 'mailto:KUNALBAJAJ7711@gmail.com?subject=Inquiry&body=Hello,%20I%20have%20an%20inquiry';

  return (
    <div className="flex flex-col md:flex-row gap-4 justify-center">
      {/* WhatsApp */}
      <a
        href={whatsappLink}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center gap-2 px-5 py-3 bg-green-500 text-white font-bold rounded-lg shadow-lg hover:bg-green-600 hover:scale-105 transform transition duration-300"
      >
        <FaWhatsapp /> WhatsApp
      </a>

      {/* Instagram */}
      <a
        href={instagramLink}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center gap-2 px-5 py-3 bg-pink-600 text-white font-bold rounded-lg shadow-lg hover:bg-pink-700 hover:scale-105 transform transition duration-300"
      >
        <FaInstagram /> Instagram
      </a>

      {/* Facebook */}
      <a
        href={facebookLink}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center gap-2 px-5 py-3 bg-blue-700 text-white font-bold rounded-lg shadow-lg hover:bg-blue-800 hover:scale-105 transform transition duration-300"
      >
        <FaFacebook /> Facebook
      </a>

      {/* Email */}
      <a
        href={emailLink}
        className="flex items-center gap-2 px-5 py-3 bg-yellow-500 text-white font-bold rounded-lg shadow-lg hover:bg-yellow-600 hover:scale-105 transform transition duration-300"
      >
        <FaEnvelope /> Email
      </a>
    </div>
  );
};

export default SocialMediaButtons;
