import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        // Input validation
        if (!username || !password) {
            alert("Please enter both username and password.");
            return;
        }

        const formData = new FormData();
        formData.append('login', 1);
        formData.append('username', username);
        formData.append('password', password);

        try {
            const response = await axios.post(
                'https://kunalbajajshowroom.coffeewithbugs.com/backend/auth.php',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    withCredentials: true,
                }
            );

            if (response.data.status === 'success') {
                alert('Login successful!');
                // Store non-sensitive data in local storage
                localStorage.setItem(
                    'user',
                    JSON.stringify({
                        username: response.data.data.BajajUsername,
                        role: response.data.data.Role,
                    })
                );
                navigate('/management');
            } else {
                alert(response.data.message || 'Invalid login attempt.');
            }
        } catch (error) {
            console.error('Login failed:', error);
            alert('Unexpected response from the server. Please try again.');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-gray-800 to-gray-900">
            <div className="bg-gray-800 rounded-lg shadow-lg p-8 w-80 md:w-96">
                <h2 className="text-2xl font-bold mb-6 text-center text-yellow-400">Login</h2>
                <form onSubmit={handleLogin} className="flex flex-col space-y-4">
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="p-3 rounded-md bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="p-3 rounded-md bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                    />
                    <button
                        type="submit"
                        className="w-full bg-yellow-500 hover:bg-yellow-600 text-white py-3 rounded-md font-semibold transition duration-200"
                    >
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
