import React, { useState } from 'react';
import axios from 'axios';

const RegisterAdmin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');

    const handleRegister = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('register', 1);
        formData.append('username', username);
        formData.append('password', password);
        formData.append('full_name', fullName);
        formData.append('email', email);

        try {
            const response = await axios.post('http://localhost/qr-contact-system/backend/register.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status === 'success') {
                alert('Admin registered successfully!');
                // Optionally, clear fields or redirect
                setUsername('');
                setPassword('');
                setFullName('');
                setEmail('');
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error('Registration failed:', error);
            alert('Failed to connect to the server. Please try again later.');
        }
    };

    return (
        <div className="register-container">
            <form onSubmit={handleRegister} className="flex flex-col items-center space-y-4">
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="px-3 py-2 rounded-md"
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="px-3 py-2 rounded-md"
                    required
                />
                <input
                    type="text"
                    placeholder="Full Name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    className="px-3 py-2 rounded-md"
                    required
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="px-3 py-2 rounded-md"
                    required
                />
                <button type="submit" className="px-6 py-2 bg-green-700 rounded-lg hover:bg-green-800 text-white">
                    Register Admin
                </button>
            </form>
        </div>
    );
};

export default RegisterAdmin;
