import React, { useState } from 'react';
import { FaWhatsapp, FaFacebook, FaEnvelope } from 'react-icons/fa'; // Icons for sharing
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; // Firebase storage functions
import { storage } from '../firebase'; // Import the configured storage

const FileSharing = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [fileURL, setFileURL] = useState('');
  const [error, setError] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = () => {
    if (!file) {
      setError('Please choose a file first!');
      return;
    }

    setUploading(true);
    setError(null);

    const storageRef = ref(storage, `uploads/${Date.now()}_${file.name}`); // Create reference in storage with unique name
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // You can add a progress bar here using snapshot.bytesTransferred and snapshot.totalBytes
      },
      (error) => {
        setUploading(false);
        setError('Error uploading the file: ' + error.message);
      },
      () => {
        // Get the file URL once upload is complete
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUploading(false);
          setFileURL(url); // Set the URL of the uploaded file
          alert('File uploaded successfully!');
        });
      }
    );
  };

  const whatsappLink = `https://api.whatsapp.com/send?text=Check%20this%20file:%20${encodeURIComponent(fileURL)}`;
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(fileURL)}`;
  const emailLink = `mailto:KUNALBAJAJ7711@gmail.com?subject=File%20Shared&body=Here%20is%20the%20file:%20${fileURL}`;

  return (
    <div className="flex flex-col items-center space-y-6 w-full md:w-2/3 lg:w-1/2 mx-auto">
      <input
        type="file"
        onChange={handleFileChange}
        className="block w-full text-gray-800 px-4 py-2 mb-4 bg-gray-200 rounded-lg shadow-lg hover:scale-105 transition-transform"
      />

      {file && (
        <button
          onClick={uploadFile}
          disabled={uploading}
          className={`w-full px-5 py-2 bg-blue-600 text-white rounded-lg shadow-lg text-center ${
            uploading ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-700'
          }`}
        >
          {uploading ? 'Uploading...' : 'Upload File'}
        </button>
      )}

      {fileURL && (
        <div className="mt-4 w-full">
          <p className="text-green-500 text-center">File uploaded! Shareable link:</p>
          <a
            href={fileURL}
            className="underline text-blue-500 block text-center overflow-hidden overflow-ellipsis break-all w-full mt-2"
          >
            {fileURL}
          </a>

          <div className="flex flex-col md:flex-row justify-center gap-4 mt-6 w-full">
            {/* WhatsApp Share */}
            <a
              href={whatsappLink}
              target="_blank"
              rel="noopener noreferrer"
              className="flex justify-center items-center gap-2 px-5 py-3 bg-green-500 text-white font-bold rounded-lg shadow-lg hover:bg-green-600 hover:scale-105 transform transition duration-300 w-full md:w-auto"
            >
              <FaWhatsapp /> Share via WhatsApp
            </a>

            {/* Facebook Share */}
            <a
              href={facebookLink}
              target="_blank"
              rel="noopener noreferrer"
              className="flex justify-center items-center gap-2 px-5 py-3 bg-blue-700 text-white font-bold rounded-lg shadow-lg hover:bg-blue-800 hover:scale-105 transform transition duration-300 w-full md:w-auto"
            >
              <FaFacebook /> Share on Facebook
            </a>

            {/* Email Share */}
            <a
              href={emailLink}
              className="flex justify-center items-center gap-2 px-5 py-3 bg-yellow-500 text-white font-bold rounded-lg shadow-lg hover:bg-yellow-600 hover:scale-105 transform transition duration-300 w-full md:w-auto"
            >
              <FaEnvelope /> Share via Email
            </a>
          </div>
        </div>
      )}

      {error && <p className="text-red-500 text-center">{error}</p>}
    </div>
  );
};

export default FileSharing;
  