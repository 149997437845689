import React, { useState, useEffect } from 'react';
import '../styles/FinanceManagement.css';
import axios from 'axios';

const FinanceManagement = () => {
    const [records, setRecords] = useState([]);
    const [newRecord, setNewRecord] = useState({
        transactionType: 'Income',
        category: '',
        description: '',
        amount: '',
        paymentMethod: '',
        transactionDate: '',
        referenceNumber: '',
        status: 'Pending',
        account: '',
        partyName: '',
        partyContact: '',
        partyEmail: '',
        invoiceNumber: '',
        taxAmount: '',
        discount: '',
        netAmount: '',
        remarks: '',
        addedBy: ''
    });
    const [editRecordId, setEditRecordId] = useState(null);
    const [excelFile, setExcelFile] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewRecord({ ...newRecord, [name]: value });
    };

    const handleFileChange = (e) => {
        setExcelFile(e.target.files[0]);
    };

    const addOrUpdateRecord = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        Object.keys(newRecord).forEach((key) => {
            formData.append(key, newRecord[key]);
        });

        if (editRecordId) {
            formData.append('edit_finance', '1');
            formData.append('Finance_ID', editRecordId);
        } else {
            formData.append('add_finance', '1');
        }

        try {
            const response = await axios.post('https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_finances.php', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            if (response.data.status === "success") {
                alert(response.data.message);
                fetchFinanceRecords();
                setEditRecordId(null);
                resetForm();
            } else {
                alert(response.data.message || "An unexpected error occurred.");
            }
        } catch (error) {
            console.error("Error adding or updating record:", error);
            alert("Failed to connect to the server.");
        }
    };

    const fetchFinanceRecords = async () => {
        try {
            const response = await axios.get('https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_finances.php?action=fetch_all');
            if (Array.isArray(response.data)) {
                setRecords(response.data);
            } else {
                setRecords([]);
                console.error("Unexpected response format:", response.data);
            }
        } catch (error) {
            console.error("Error fetching records:", error);
        }
    };

    const handleEdit = (record) => {
        setNewRecord({
            transactionType: record.Transaction_Type,
            category: record.Category,
            description: record.Description,
            amount: record.Amount,
            paymentMethod: record.Payment_Method,
            transactionDate: record.Transaction_Date,
            referenceNumber: record.Reference_Number,
            status: record.Status,
            account: record.Account,
            partyName: record.Party_Name,
            partyContact: record.Party_Contact,
            partyEmail: record.Party_Email,
            invoiceNumber: record.Invoice_Number,
            taxAmount: record.Tax_Amount,
            discount: record.Discount,
            netAmount: record.Net_Amount,
            remarks: record.Remarks,
            addedBy: record.Added_By
        });
        setEditRecordId(record.Finance_ID);
    };

    const handleDelete = async (financeId) => {
        if (window.confirm("Are you sure you want to delete this record?")) {
            try {
                const formData = new FormData();
                formData.append("delete_finance", "1");
                formData.append("Finance_ID", financeId);

                const response = await axios.post('https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_finances.php', formData);

                if (response.data.status === "success") {
                    alert(response.data.message);
                    fetchFinanceRecords();
                } else {
                    alert(response.data.message || "An unexpected error occurred.");
                }
            } catch (error) {
                console.error("Error deleting record:", error);
                alert("Failed to connect to the server.");
            }
        }
    };

    const handleExcelUpload = async (e) => {
        e.preventDefault();
        if (!excelFile) {
            alert("Please select an Excel file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("excelFile", excelFile);

        try {
            const response = await axios.post('https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_finances.php', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            if (response.data.status === "success") {
                alert(response.data.message);
                fetchFinanceRecords();
                setExcelFile(null);
            } else {
                alert(response.data.message || "An unexpected error occurred.");
            }
        } catch (error) {
            console.error("Error uploading Excel file:", error);
            alert("Failed to connect to the server.");
        }
    };

    const downloadExcel = () => {
        window.location.href = 'https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_finances.php?action=download_excel';
    };

    const resetForm = () => {
        setNewRecord({
            transactionType: 'Income',
            category: '',
            description: '',
            amount: '',
            paymentMethod: '',
            transactionDate: '',
            referenceNumber: '',
            status: 'Pending',
            account: '',
            partyName: '',
            partyContact: '',
            partyEmail: '',
            invoiceNumber: '',
            taxAmount: '',
            discount: '',
            netAmount: '',
            remarks: '',
            addedBy: ''
        });
        setExcelFile(null);
    };

    useEffect(() => {
        fetchFinanceRecords();
    }, []);

    return (
        <div className="container mx-auto p-6 bg-dark-gray text-white rounded-lg shadow-lg">
            <h2 className="text-3xl font-bold mb-6 text-blue-400">Finance Management</h2>
            <form className="mb-6" onSubmit={addOrUpdateRecord}>
                <div className="grid grid-cols-2 gap-4">
                    <select name="transactionType" className="p-2 bg-dark-input rounded" value={newRecord.transactionType} onChange={handleInputChange}>
                        <option value="Income">Income</option>
                        <option value="Expense">Expense</option>
                    </select>
                    <input type="text" name="category" placeholder="Category" className="p-2 bg-dark-input rounded" value={newRecord.category} onChange={handleInputChange} />
                    <input type="text" name="description" placeholder="Description" className="p-2 bg-dark-input rounded" value={newRecord.description} onChange={handleInputChange} />
                    <input type="number" name="amount" placeholder="Amount" className="p-2 bg-dark-input rounded" value={newRecord.amount} onChange={handleInputChange} />
                    <input type="text" name="paymentMethod" placeholder="Payment Method" className="p-2 bg-dark-input rounded" value={newRecord.paymentMethod} onChange={handleInputChange} />
                    <input type="date" name="transactionDate" className="p-2 bg-dark-input rounded" value={newRecord.transactionDate} onChange={handleInputChange} />
                    <input type="text" name="referenceNumber" placeholder="Reference Number" className="p-2 bg-dark-input rounded" value={newRecord.referenceNumber} onChange={handleInputChange} />
                    <input type="text" name="account" placeholder="Account" className="p-2 bg-dark-input rounded" value={newRecord.account} onChange={handleInputChange} />
                    <input type="text" name="partyName" placeholder="Party Name" className="p-2 bg-dark-input rounded" value={newRecord.partyName} onChange={handleInputChange} />
                    <input type="text" name="partyContact" placeholder="Party Contact" className="p-2 bg-dark-input rounded" value={newRecord.partyContact} onChange={handleInputChange} />
                    <input type="text" name="partyEmail" placeholder="Party Email" className="p-2 bg-dark-input rounded" value={newRecord.partyEmail} onChange={handleInputChange} />
                    <input type="text" name="invoiceNumber" placeholder="Invoice Number" className="p-2 bg-dark-input rounded" value={newRecord.invoiceNumber} onChange={handleInputChange} />
                    <input type="number" name="taxAmount" placeholder="Tax Amount" className="p-2 bg-dark-input rounded" value={newRecord.taxAmount} onChange={handleInputChange} />
                    <input type="number" name="discount" placeholder="Discount" className="p-2 bg-dark-input rounded" value={newRecord.discount} onChange={handleInputChange} />
                    <input type="number" name="netAmount" placeholder="Net Amount" className="p-2 bg-dark-input rounded" value={newRecord.netAmount} onChange={handleInputChange} />
                    <textarea name="remarks" placeholder="Remarks" className="p-2 bg-dark-input rounded" value={newRecord.remarks} onChange={handleInputChange}></textarea>
                    <input type="text" name="addedBy" placeholder="Added By" className="p-2 bg-dark-input rounded" value={newRecord.addedBy} onChange={handleInputChange} />
                </div>
                <button type="submit" className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded mt-4">
                    {editRecordId ? 'Update Record' : 'Add Record'}
                </button>
            </form>

            <form className="mb-6" onSubmit={handleExcelUpload}>
                <input type="file" name="excelFile" className="p-2 bg-dark-input rounded mb-2" onChange={handleFileChange} />
                <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded">
                    Upload Excel
                </button>
            </form>

            <button onClick={downloadExcel} className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded mb-6">
                Download Excel
            </button>

            <h3 className="text-2xl font-semibold mb-4 text-green-400">Finance Records</h3>
            <table className="w-full bg-dark-gray rounded border-collapse">
                <thead>
                    <tr className="bg-dark-header">
                        <th className="p-2 border">Type</th>
                        <th className="p-2 border">Category</th>
                        <th className="p-2 border">Description</th>
                        <th className="p-2 border">Amount</th>
                        <th className="p-2 border">Payment Method</th>
                        <th className="p-2 border">Date</th>
                        <th className="p-2 border">Reference No</th>
                        <th className="p-2 border">Account</th>
                        <th className="p-2 border">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(records) && records.length > 0 ? (
                        records.map((record, index) => (
                            <tr key={index} className="hover:bg-dark-hover">
                                <td className="p-2 border">{record.Transaction_Type}</td>
                                <td className="p-2 border">{record.Category}</td>
                                <td className="p-2 border">{record.Description}</td>
                                <td className="p-2 border">{record.Amount}</td>
                                <td className="p-2 border">{record.Payment_Method}</td>
                                <td className="p-2 border">{record.Transaction_Date}</td>
                                <td className="p-2 border">{record.Reference_Number}</td>
                                <td className="p-2 border">{record.Account}</td>
                                <td className="p-2 border">
                                    <button onClick={() => handleEdit(record)} className="bg-blue-500 text-white p-1 rounded mr-2">Edit</button>
                                    <button onClick={() => handleDelete(record.Finance_ID)} className="bg-red-500 text-white p-1 rounded">Delete</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="9" className="p-2 text-center text-gray-400">No records available.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default FinanceManagement;
