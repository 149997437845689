import React, { useState, useEffect } from 'react';
import '../styles/AccessoryManagement.css';
import axios from 'axios';

const AccessoryManagement = () => {
    const [accessories, setAccessories] = useState([]);
    const [newAccessory, setNewAccessory] = useState({
        name: '',
        category: '',
        brand: '',
        stockQuantity: '',
        purchasePrice: '',
        salePrice: '',
        dateOfEntry: '',
        supplier: '',
        warrantyPeriod: '',
        status: ''
    });
    const [editAccessoryId, setEditAccessoryId] = useState(null);
    const [excelFile, setExcelFile] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAccessory({ ...newAccessory, [name]: value });
    };

    const handleExcelFileChange = (e) => {
        setExcelFile(e.target.files[0]);
    };

    const addOrUpdateAccessory = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        Object.keys(newAccessory).forEach((key) => {
            formData.append(key, newAccessory[key]);
        });

        // Check if we are editing or adding a new accessory
        if (editAccessoryId) {
            formData.append('edit_accessory', '1');
            formData.append('Accessory_ID', editAccessoryId);
        } else {
            formData.append('add_accessory', '1');
        }

        try {
            const response = await axios.post('https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_accessories.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.status === "success") {
                alert(response.data.message);
                fetchAccessories(); // Fetch the updated list after adding
                setEditAccessoryId(null);
                resetForm();
            } else {
                alert(response.data.message || "An unexpected error occurred.");
            }
        } catch (error) {
            console.error("Error adding or updating accessory:", error);
            alert("Failed to connect to the server. Check if the backend is running.");
        }
    };

    const fetchAccessories = async () => {
        try {
            const response = await axios.get('https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_accessories.php?action=fetch_all');
            if (Array.isArray(response.data)) {
                setAccessories(response.data);
            } else {
                setAccessories([]);
                console.error("Unexpected response format:", response.data);
            }
        } catch (error) {
            console.error("Error fetching accessories:", error);
        }
    };

    const handleEdit = (accessory) => {
        setNewAccessory({
            name: accessory.Name,
            category: accessory.Category,
            brand: accessory.Brand,
            stockQuantity: accessory.Stock_Quantity,
            purchasePrice: accessory.Purchase_Price,
            salePrice: accessory.Sale_Price,
            dateOfEntry: accessory.Date_Of_Entry,
            supplier: accessory.Supplier,
            warrantyPeriod: accessory.Warranty_Period,
            status: accessory.Status
        });
        setEditAccessoryId(accessory.Accessory_ID);
    };

    const handleDelete = async (accessoryId) => {
        if (window.confirm("Are you sure you want to delete this accessory?")) {
            try {
                const formData = new FormData();
                formData.append("delete_accessory", "1");
                formData.append("Accessory_ID", accessoryId);

                const response = await axios.post('https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_accessories.php', formData);

                if (response.data.status === "success") {
                    alert(response.data.message);
                    fetchAccessories(); // Refresh list
                } else {
                    alert(response.data.message || "An unexpected error occurred.");
                }
            } catch (error) {
                console.error("Error deleting accessory:", error);
                alert("Failed to connect to the server.");
            }
        }
    };

    const handleExcelUpload = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('excelFile', excelFile);

        try {
            const response = await axios.post('https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_accessories.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.status === "success") {
                alert(response.data.message);
                fetchAccessories(); // Refresh list after importing
                setExcelFile(null);
            } else {
                alert(response.data.message || "An unexpected error occurred.");
            }
        } catch (error) {
            console.error("Error uploading Excel file:", error);
            alert("Failed to connect to the server.");
        }
    };

    const downloadExcel = async () => {
        try {
            const response = await axios.get('https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_accessories.php?action=download_excel', {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'accessories.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("Error downloading Excel:", error);
        }
    };

    const resetForm = () => {
        setNewAccessory({
            name: '',
            category: '',
            brand: '',
            stockQuantity: '',
            purchasePrice: '',
            salePrice: '',
            dateOfEntry: '',
            supplier: '',
            warrantyPeriod: '',
            status: ''
        });
        setExcelFile(null);
    };

    useEffect(() => {
        fetchAccessories();
    }, []);

    return (
        <div className="container mx-auto p-6 bg-dark-gray text-white rounded-lg shadow-lg">
            <h2 className="text-3xl font-bold mb-6 text-blue-400">Manage Accessories</h2>
            <form className="mb-6" onSubmit={addOrUpdateAccessory}>
                <div className="grid grid-cols-2 gap-4">
                    <input type="text" name="name" placeholder="Accessory Name" className="p-2 bg-dark-input rounded" value={newAccessory.name} onChange={handleInputChange} />
                    <input type="text" name="category" placeholder="Category" className="p-2 bg-dark-input rounded" value={newAccessory.category} onChange={handleInputChange} />
                    <input type="text" name="brand" placeholder="Brand" className="p-2 bg-dark-input rounded" value={newAccessory.brand} onChange={handleInputChange} />
                    <input type="number" name="stockQuantity" placeholder="Stock Quantity" className="p-2 bg-dark-input rounded" value={newAccessory.stockQuantity} onChange={handleInputChange} />
                    <input type="number" name="purchasePrice" placeholder="Purchase Price" className="p-2 bg-dark-input rounded" value={newAccessory.purchasePrice} onChange={handleInputChange} />
                    <input type="number" name="salePrice" placeholder="Sale Price" className="p-2 bg-dark-input rounded" value={newAccessory.salePrice} onChange={handleInputChange} />
                    <input type="date" name="dateOfEntry" className="p-2 bg-dark-input rounded" value={newAccessory.dateOfEntry} onChange={handleInputChange} />
                    <input type="text" name="supplier" placeholder="Supplier" className="p-2 bg-dark-input rounded" value={newAccessory.supplier} onChange={handleInputChange} />
                    <input type="text" name="warrantyPeriod" placeholder="Warranty Period" className="p-2 bg-dark-input rounded" value={newAccessory.warrantyPeriod} onChange={handleInputChange} />
                    <select name="status" className="p-2 bg-dark-input rounded" value={newAccessory.status} onChange={handleInputChange}>
                        <option value="">Select Status</option>
                        <option value="Available">Available</option>
                        <option value="Out of Stock">Out of Stock</option>
                    </select>
                </div>
                <button type="submit" className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded mt-4">
                    {editAccessoryId ? 'Update Accessory' : 'Add Accessory'}
                </button>
                <button type="button" className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mt-4 ml-4" onClick={downloadExcel}>
                    Download Excel
                </button>
            </form>

            <form className="mb-6" onSubmit={handleExcelUpload}>
                <input type="file" className="p-2 bg-dark-input rounded" onChange={handleExcelFileChange} />
                <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mt-4 ml-2">
                    Upload Excel
                </button>
            </form>

            <h3 className="text-2xl font-semibold mb-4 text-green-400">Accessory Records</h3>
            <table className="w-full bg-dark-gray rounded border-collapse">
                <thead>
                    <tr className="bg-dark-header">
                        <th className="p-2 border">Name</th>
                        <th className="p-2 border">Category</th>
                        <th className="p-2 border">Brand</th>
                        <th className="p-2 border">Stock</th>
                        <th className="p-2 border">Purchase Price</th>
                        <th className="p-2 border">Sale Price</th>
                        <th className="p-2 border">Supplier</th>
                        <th className="p-2 border">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(accessories) && accessories.length > 0 ? (
                        accessories.map((accessory, index) => (
                            <tr key={index} className="hover:bg-dark-hover">
                                <td className="p-2 border">{accessory.Name}</td>
                                <td className="p-2 border">{accessory.Category}</td>
                                <td className="p-2 border">{accessory.Brand}</td>
                                <td className="p-2 border">{accessory.Stock_Quantity}</td>
                                <td className="p-2 border">{accessory.Purchase_Price}</td>
                                <td className="p-2 border">{accessory.Sale_Price}</td>
                                <td className="p-2 border">{accessory.Supplier}</td>
                                <td className="p-2 border">
                                    <button onClick={() => handleEdit(accessory)} className="bg-blue-500 text-white p-1 rounded mr-2">Edit</button>
                                    <button onClick={() => handleDelete(accessory.Accessory_ID)} className="bg-red-500 text-white p-1 rounded">Delete</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="8" className="p-2 text-center text-gray-400">No accessories available.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default AccessoryManagement;
