import React, { useState, useEffect } from 'react';
import '../styles/BikeManagement.css';
import axios from 'axios';

const BikeManagement = () => {
    const [bikes, setBikes] = useState([]);
    const [newBike, setNewBike] = useState({
        model: '',
        chassisNumber: '',
        engineNumber: '',
        colour: '',
        finalPrice: '',
        salePrice: '',
        dateOfSale: '',
        paymentMethod: '',
        salesPerson: '',
        origin: '',
        customerFile: null
    });
    const [editBikeId, setEditBikeId] = useState(null);
    const [excelFile, setExcelFile] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewBike({ ...newBike, [name]: value });
    };

    const handleFileChange = (e) => {
        setNewBike({ ...newBike, customerFile: e.target.files[0] });
    };

    const handleExcelChange = (e) => {
        setExcelFile(e.target.files[0]);
    };

    const addOrUpdateBike = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        Object.keys(newBike).forEach((key) => {
            formData.append(key, newBike[key]);
        });

        // Check if we are editing or adding a new bike
        if (editBikeId) {
            formData.append('edit_bike', '1');
            formData.append('Sale_ID', editBikeId);
        } else {
            formData.append('add_bike', '1');
        }

        try {
            const response = await axios.post('https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_bikes.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.status === "success") {
                alert(response.data.message);
                fetchBikeSales(); // Fetch the updated list after adding
                setEditBikeId(null);
                resetForm();
            } else {
                alert(response.data.message || "An unexpected error occurred.");
            }
        } catch (error) {
            console.error("Error adding or updating bike:", error);
            alert("Failed to connect to the server. Check if the backend is running.");
        }
    };

    const fetchBikeSales = async () => {
        try {
            const response = await axios.get('https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_bikes.php?action=fetch_all');
            if (Array.isArray(response.data)) {
                setBikes(response.data);
            } else {
                setBikes([]);
                console.error("Unexpected response format:", response.data);
            }
        } catch (error) {
            console.error("Error fetching bike sales:", error);
        }
    };

    const handleEdit = (bike) => {
        setNewBike({
            model: bike.Bike_Model,
            chassisNumber: bike.Chassis_Number,
            engineNumber: bike.Engine_Number,
            colour: bike.Colour,
            finalPrice: bike.Final_Price,
            salePrice: bike.Sale_Price,
            dateOfSale: bike.Date_Of_Sale,
            paymentMethod: bike.Payment_Method,
            salesPerson: bike.Sales_Person,
            origin: bike.Origin,
            customerFile: null
        });
        setEditBikeId(bike.Sale_ID);
    };

    const handleDelete = async (saleId) => {
        if (window.confirm("Are you sure you want to delete this bike?")) {
            try {
                const formData = new FormData();
                formData.append("delete_bike", "1");
                formData.append("Sale_ID", saleId);

                const response = await axios.post('https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_bikes.php', formData);

                if (response.data.status === "success") {
                    alert(response.data.message);
                    fetchBikeSales(); // Refresh list
                } else {
                    alert(response.data.message || "An unexpected error occurred.");
                }
            } catch (error) {
                console.error("Error deleting bike:", error);
                alert("Failed to connect to the server.");
            }
        }
    };

    const handleExcelUpload = async () => {
        if (!excelFile) {
            alert("Please select an Excel file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("excelFile", excelFile);

        try {
            const response = await axios.post('https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_bikes.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.status === "success") {
                alert(response.data.message);
                fetchBikeSales(); // Refresh list after upload
                setExcelFile(null);
            } else {
                alert(response.data.message || "An unexpected error occurred.");
            }
        } catch (error) {
            console.error("Error uploading Excel file:", error);
            alert("Failed to connect to the server.");
        }
    };

    const downloadExcel = () => {
        window.location.href = 'https://kunalbajajshowroom.coffeewithbugs.com/backend/manage_bikes.php?action=download_excel';
    };

    const resetForm = () => {
        setNewBike({
            model: '',
            chassisNumber: '',
            engineNumber: '',
            colour: '',
            finalPrice: '',
            salePrice: '',
            dateOfSale: '',
            paymentMethod: '',
            salesPerson: '',
            origin: '',
            customerFile: null
        });
    };

    useEffect(() => {
        fetchBikeSales();
    }, []);

    return (
        <div className="container mx-auto p-6 bg-dark-gray text-white rounded-lg shadow-lg">
            <h2 className="text-3xl font-bold mb-6 text-blue-400">Manage Bikes</h2>
            <form className="mb-6" onSubmit={addOrUpdateBike}>
                <div className="grid grid-cols-2 gap-4">
                    <input type="text" name="model" placeholder="Model" className="p-2 bg-dark-input rounded" value={newBike.model} onChange={handleInputChange} />
                    <input type="text" name="chassisNumber" placeholder="Chassis Number" className="p-2 bg-dark-input rounded" value={newBike.chassisNumber} onChange={handleInputChange} />
                    <input type="text" name="engineNumber" placeholder="Engine Number" className="p-2 bg-dark-input rounded" value={newBike.engineNumber} onChange={handleInputChange} />
                    <input type="text" name="colour" placeholder="Colour" className="p-2 bg-dark-input rounded" value={newBike.colour} onChange={handleInputChange} />
                    <input type="number" name="finalPrice" placeholder="Final Price" className="p-2 bg-dark-input rounded" value={newBike.finalPrice} onChange={handleInputChange} />
                    <input type="number" name="salePrice" placeholder="Sale Price" className="p-2 bg-dark-input rounded" value={newBike.salePrice} onChange={handleInputChange} />
                    <input type="date" name="dateOfSale" className="p-2 bg-dark-input rounded" value={newBike.dateOfSale} onChange={handleInputChange} />
                    <input type="text" name="paymentMethod" placeholder="Payment Method" className="p-2 bg-dark-input rounded" value={newBike.paymentMethod} onChange={handleInputChange} />
                    <input type="text" name="salesPerson" placeholder="Sales Person" className="p-2 bg-dark-input rounded" value={newBike.salesPerson} onChange={handleInputChange} />
                    <select name="origin" className="p-2 bg-dark-input rounded" value={newBike.origin} onChange={handleInputChange}>
                        <option value="">Select Origin</option>
                        <option value="Kawardha">Kawardha</option>
                        <option value="Pandatarai">Pandatarai</option>
                        <option value="Pandariya">Pandariya</option>
                        <option value="Other">Other</option>
                    </select>
                    <input type="file" name="customerFile" className="p-2 bg-dark-input rounded" onChange={handleFileChange} />
                </div>
                <button type="submit" className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded mt-4">
                    {editBikeId ? 'Update Bike' : 'Add Bike'}
                </button>
            </form>

            <div className="mb-6">
                <input type="file" onChange={handleExcelChange} className="p-2 bg-dark-input rounded" />
                <button onClick={handleExcelUpload} className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mt-2">Upload Excel</button>
                <button onClick={downloadExcel} className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded mt-2 ml-2">Download Excel</button>
            </div>

            <h3 className="text-2xl font-semibold mb-4 text-green-400">Bike Sales</h3>
            <table className="w-full bg-dark-gray rounded border-collapse">
                <thead>
                    <tr className="bg-dark-header">
                        <th className="p-2 border">Model</th>
                        <th className="p-2 border">Chassis No.</th>
                        <th className="p-2 border">Engine No.</th>
                        <th className="p-2 border">Colour</th>
                        <th className="p-2 border">Final Price</th>
                        <th className="p-2 border">Sale Price</th>
                        <th className="p-2 border">Date of Sale</th>
                        <th className="p-2 border">Payment Method</th>
                        <th className="p-2 border">Sales Person</th>
                        <th className="p-2 border">Origin</th>
                        <th className="p-2 border">Customer File</th>
                        <th className="p-2 border">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(bikes) && bikes.length > 0 ? (
                        bikes.map((bike, index) => (
                            <tr key={index} className="hover:bg-dark-hover">
                                <td className="p-2 border">{bike.Bike_Model}</td>
                                <td className="p-2 border">{bike.Chassis_Number}</td>
                                <td className="p-2 border">{bike.Engine_Number}</td>
                                <td className="p-2 border">{bike.Colour}</td>
                                <td className="p-2 border">{bike.Final_Price}</td>
                                <td className="p-2 border">{bike.Sale_Price}</td>
                                <td className="p-2 border">{bike.Date_Of_Sale}</td>
                                <td className="p-2 border">{bike.Payment_Method}</td>
                                <td className="p-2 border">{bike.Sales_Person}</td>
                                <td className="p-2 border">{bike.Origin}</td>
                                <td className="p-2 border">
                                    {bike.Customer_File ? (
                                        <img
                                            src={`https://kunalbajajshowroom.coffeewithbugs.com/backend/uploads/${bike.Customer_File}`}
                                            alt="Customer File"
                                            className="w-10 h-10 object-cover hover:w-20 hover:h-20 transition"
                                        />
                                    ) : 'N/A'}
                                </td>
                                <td className="p-2 border">
                                    <button onClick={() => handleEdit(bike)} className="bg-blue-500 text-white p-1 rounded mr-2">Edit</button>
                                    <button onClick={() => handleDelete(bike.Sale_ID)} className="bg-red-500 text-white p-1 rounded">Delete</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="12" className="p-2 text-center text-gray-400">No bike sales available.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default BikeManagement;
