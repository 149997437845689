import React, { useState, useEffect } from 'react';
import '../styles/BikeManagement.css';
import axios from 'axios';

const TestBikeManagement = () => {
    const [bikeModel, setBikeModel] = useState('');
    const [bikeOrigin, setBikeOrigin] = useState('');
    const [bikes, setBikes] = useState([]);
    const [message, setMessage] = useState('');
    const [editId, setEditId] = useState(null);

    useEffect(() => {
        fetchBikes();
    }, []);

    const fetchBikes = async () => {
        try {
            const response = await axios.get('https://kunalbajajshowroom.coffeewithbugs.com/backend/bike_management.php');
            if (Array.isArray(response.data)) {
                setBikes(response.data);
            } else {
                setBikes([]);
                console.error("Unexpected data format:", response.data);
            }
        } catch (error) {
            console.error("Error fetching the bike list:", error);
        }
    };

    const handleAddBike = async () => {
        try {
            const response = await axios.post('https://kunalbajajshowroom.coffeewithbugs.com/backend/bike_management.php', {
                bikeModel: bikeModel,
                bikeOrigin: bikeOrigin
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            setMessage(response.data.message || response.data.error);
            fetchBikes();
            setBikeModel('');
            setBikeOrigin('');
        } catch (error) {
            setMessage("Error adding the bike details.");
            console.error(error);
        }
    };

    const handleEditBike = (bike) => {
        setBikeModel(bike.bike_model);
        setBikeOrigin(bike.bike_origin);
        setEditId(bike.bike_id);
    };

    const handleUpdateBike = async () => {
        try {
            const response = await axios.put('https://kunalbajajshowroom.coffeewithbugs.com/backend/bike_management.php', {
                id: editId,
                bikeModel: bikeModel,
                bikeOrigin: bikeOrigin
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            setMessage(response.data.message || response.data.error);
            setEditId(null);
            setBikeModel('');
            setBikeOrigin('');
            fetchBikes();
        } catch (error) {
            setMessage("Error updating the bike details.");
            console.error(error);
        }
    };

    const handleDeleteBike = async (id) => {
        try {
            const response = await axios.delete('https://kunalbajajshowroom.coffeewithbugs.com/backend/bike_management.php', {
                data: { id: id },
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            setMessage(response.data.message || response.data.error);
            fetchBikes();
        } catch (error) {
            setMessage("Error deleting the bike.");
            console.error(error);
        }
    };

    return (
        <div className="container">
            <h2>Bike Management</h2>
            <div className="form">
                <input
                    type="text"
                    placeholder="Bike Model"
                    value={bikeModel}
                    onChange={(e) => setBikeModel(e.target.value)}
                    style={{ marginRight: '10px' }}
                />
                <input
                    type="text"
                    placeholder="Bike Origin"
                    value={bikeOrigin}
                    onChange={(e) => setBikeOrigin(e.target.value)}
                    style={{ marginRight: '10px' }}
                />
                {editId ? (
                    <button onClick={handleUpdateBike} className="update-btn">Update Bike</button>
                ) : (
                    <button onClick={handleAddBike} className="add-btn">Add Bike</button>
                )}
            </div>
            {message && <p>{message}</p>}

            <h3>Bike List</h3>
            {bikes.length > 0 ? (
                <table className="bike-table">
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>Origin</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bikes.map((bike) => (
                            <tr key={bike.bike_id}>
                                <td>{bike.bike_model}</td>
                                <td>{bike.bike_origin}</td>
                                <td>
                                    <button onClick={() => handleEditBike(bike)} className="edit-btn">Edit</button>
                                    <button onClick={() => handleDeleteBike(bike.bike_id)} className="delete-btn">Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No bikes available.</p>
            )}
        </div>
    );
};

export default TestBikeManagement;
