import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const ManagementDashboard = () => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            const formData = new FormData();
            formData.append('logout', 1);

            const response = await axios.post('https://kunalbajajshowroom.coffeewithbugs.com/backend/auth.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true, // Send cookies along with the request
            });

            if (response.data.status === 'success') {
                localStorage.removeItem('user');
                navigate('/login');
                alert(response.data.message);
            } else {
                console.error('Logout failed response:', response.data);
                alert(response.data.message || 'Logout failed. Please try again.');
            }
        } catch (error) {
            console.error('Logout failed:', error);
            alert('Failed to connect to the server. Please try again later.');
        }
    };

    return (
        <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center py-10">
            <h2 className="text-3xl md:text-4xl font-bold mb-8 text-yellow-500">Management Dashboard</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl">
                <Link to="/management/bikes" className="p-6 bg-blue-700 rounded-lg hover:bg-blue-800 text-center transition duration-300">
                    Manage Bikes
                </Link>
                <Link to="/management/test_bikes" className="p-6 bg-blue-700 rounded-lg hover:bg-blue-800 text-center transition duration-300">
                    Manage Test Bikes
                </Link>
                <Link to="/management/accessories" className="p-6 bg-green-700 rounded-lg hover:bg-green-800 text-center transition duration-300">
                    Manage Accessories
                </Link>
                <Link to="/management/customers" className="p-6 bg-yellow-600 rounded-lg hover:bg-yellow-700 text-center transition duration-300">
                    Manage Customers
                </Link>
                <Link to="/management/finances" className="p-6 bg-red-600 rounded-lg hover:bg-red-700 text-center transition duration-300">
                    Manage Finances
                </Link>
            </div>
            <button
                onClick={handleLogout}
                className="mt-8 px-6 py-2 bg-red-700 rounded-lg hover:bg-red-800 text-center transition duration-300"
            >
                Logout
            </button>
        </div>
    );
};

export default ManagementDashboard;
